<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <h2>Articles</h2>
      </b-col>
      <b-col cols="*" class="mr-3">
        <b-button variant="primary" :to="{ name: 'createArticle' }"
          >Create
        </b-button>
      </b-col>
    </b-row>
    <hr class="mt-1 mb-3" />
    <div>
      <b-form-input
        v-model="query"
        id="article-search-input"
        type="text"
        autocomplete="off"
        placeholder="Search for article"
        debounce="500"
        class="mb-3"
      ></b-form-input>

      <b-table
        id="article-table"
        ref="table"
        :items="provider"
        :filter="query"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        @row-clicked="rowClicked"
        style="cursor: pointer"
        empty-text="No articles found."
        show-empty
      >
        <template v-slot:cell(thumbnail_url)="row">
          <b-img-lazy
            :src="row.item.thumbnail_url"
            style="max-width: 100px; max-height: 100px"
          />
        </template>

        <template v-slot:cell(created_at)="row">
          {{ toLocalTime(row.item.created_at) }}
        </template>

        <template v-slot:cell(article_type)="row">
          {{ articleTypeMap[row.item.article_type] }}
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <ArticleButton :article="row.item" @changed="$refs.table.refresh()" />
        </template>
      </b-table>

      <b-row align-h="between" class="mt-3">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
        <b-col cols="*" class="mr-3"> {{ rows }} articles </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import ArticleButton from "./ArticleButton.vue";

export default {
  components: {
    ArticleButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      rows: 0,
      query: "",
      articleTypeMap: {
        article: "Article",
        video: "Video",
        news: "News",
        advice: "Advice",
      },
      fields: [
        { key: "title", label: "Title", sortable: true },
        { key: "thumbnail_url", label: "Thumbnail" },
        { key: "slug", label: "Slug" },
        { key: "article_type", label: "Type" },
        {
          key: "published",
          label: "Published?",
          formatter: (value) => {
            return value ? "Yes" : "No";
          },
        },
        { key: "num_views", label: "View Count" },
        {
          key: "created_at",
          label: "Date Created",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    toLocalTime(timeString) {
      let date = new Date(Date.parse(timeString));
      return date.toLocaleDateString();
    },
    rowClicked(item) {
      this.$router.push({ name: "editArticle", params: { id: item.id } });
    },
    async provider(ctx) {
      try {
        let response = await this.$http.get(
          this.$store.state.apiURL + "/articles",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
            params: {
              query: ctx.filter,
              sort: ctx.sortBy != "" ? ctx.sortBy : "none",
              direction: ctx.sortDesc ? "desc" : "asc",
              per: ctx.perPage,
              page: ctx.currentPage,
            },
          }
        );
        this.currentPage = response.data.page.position.current;
        this.rows = response.data.page.data.total;

        return response.data.data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 

</style>