<template>
  <b-dropdown
    no-caret
    size="sm"
    variant="primary"
    :disabled="deleting || duplicating"
  >
    <template v-slot:button-content>
      <b-icon icon="three-dots-vertical" />
      <b-spinner small v-if="deleting || duplicating" />
    </template>
    <b-dropdown-item @click="deleteArticle" :disabled="deleting"
      ><b-icon icon="trash-fill" scale="0.75" />Delete</b-dropdown-item
    >
    <b-dropdown-item @click="duplicateArticle" :disabled="duplicating"
      ><b-icon icon="files" scale="0.75" />Duplicate</b-dropdown-item
    >
    <b-dropdown-item @click="previewArticle"
      ><b-icon icon="eye-fill" scale="0.75" />Preview</b-dropdown-item
    >
    <b-dropdown-item @click="copyURL"
      ><b-icon icon="share-fill" scale="0.75" />Copy URL</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
      duplicating: false,
    };
  },
  methods: {
    async deleteArticle() {
      this.deleting = true;
      try {
        await this.$http.delete(
          this.$store.state.apiURL + "/articles/" + this.article.id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.$emit("changed");
      } catch (error) {
        this.handleError(error);
      }
      this.deleting = false;
    },
    async duplicateArticle() {
      this.duplicating = true;
      try {
        await this.$http.put(
          this.$store.state.apiURL +
            "/articles/" +
            this.article.id +
            "/duplicate",
          null,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.$emit("changed");
      } catch (error) {
        this.handleError(error);
      }
      this.duplicating = false;
    },
    previewArticle() {
      this.$router.push({
        name: "previewArticle",
        params: { id: this.article.id },
      });
    },
    updateClipboard(newClip) {
      navigator.clipboard.writeText(newClip).then(
        function () {
          /* clipboard successfully set */
          this.showToast(
            "URL succesfully copied to clipboard!",
            "Success!",
            "success"
          );
        }.bind(this),
        function () {
          /* clipboard write failed */
          this.showToast(
            "Could not copy URL to clipboard!",
            "Error!",
            "danger"
          );
        }.bind(this)
      );
    },
    copyURL() {
      this.updateClipboard(
        `https://TODO: CHANGE THIS/articles/${this.article.slug}`
      );
    },
  },
};
</script>
